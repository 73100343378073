import { ckoApi } from '@noah-labs/fe-shared-data-access-cko';
import { api as dataAccessSharedApi } from '@noah-labs/fe-shared-data-access-shared';
import { api as dataAccessSigningApi } from '@noah-labs/fe-shared-data-access-signing';
import { setConfig as setDataAccessWalletConfig } from '@noah-labs/fe-shared-data-access-wallet';
import { setConfig as setFeatureAnalyticsConfig } from '@noah-labs/fe-shared-feature-analytics';
import { setConfig as setFeatureSigningConfig } from '@noah-labs/fe-shared-feature-signing';
import { setConfig as setFeatureUserConfig } from '@noah-labs/fe-shared-feature-user';
import { setConfig as setFeatureWalletConfig } from '@noah-labs/fe-shared-feature-wallet';
import { setConfig as setUiComponentsConfig } from '@noah-labs/fe-shared-ui-components';
import { setConfig as setUiSharedConfig } from '@noah-labs/fe-shared-ui-shared';
import type { TpCloudWebConfig } from './webConfigBrowser';

export function setModuleConfigs(webConfigBrowser: TpCloudWebConfig, isProd: boolean): void {
  const {
    cko,
    graphql,
    intercom: { customLauncherSelector },
  } = webConfigBrowser;

  /**
   * Configure APIs and Imported modules
   */
  dataAccessSharedApi.configure(graphql);
  dataAccessSigningApi.configure(webConfigBrowser.signing.url);
  ckoApi.configure(cko.url, cko.publicKey);

  setFeatureAnalyticsConfig({
    ...webConfigBrowser.analytics,
    writeKey: webConfigBrowser.segment.writeKey,
  });

  setFeatureUserConfig({
    lnDomain: webConfigBrowser.lnDomain,
  });

  setUiComponentsConfig({
    enableTimers: webConfigBrowser.settings.enableTimers,
    intercom: { customLauncherSelector },
  });

  setUiSharedConfig({
    cko: webConfigBrowser.cko,
    manualRamp: webConfigBrowser.manualRamp,
  });

  setDataAccessWalletConfig({
    currencies: webConfigBrowser.currencies,
    isProd,
    manualRamp: webConfigBrowser.manualRamp,
    networkFee: webConfigBrowser.networkFee,
  });

  setFeatureSigningConfig({
    isProd,
  });

  setFeatureWalletConfig({
    enableLoaderAnimations: webConfigBrowser.settings.enableLoaderAnimations,
    isProd,
    networkFee: webConfigBrowser.networkFee,
  });
}
